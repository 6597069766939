import React from 'react';
import '../../styles/main.scss';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { graphql, useStaticQuery } from 'gatsby';
import { getUrlFromNavItem } from '../../lib/helpers';

export function LayoutContent({ children }) {
  const {
    companyInfo,
    siteSettings: {
      slogan,
      socialMedia,
      footerNav,
      mainNav,
      startPage: { id: startPageId },
    },
  } = useStaticQuery(
    graphql`
      query {
        companyInfo: sanityCompanyInfo(_id: { eq: "companyInfo" }) {
          name
          phone
          street
          zip
          email
          country
          city
          meetingLink
        }

        siteSettings: sanitySiteSettings(_id: { eq: "siteSettings" }) {
          slogan
          socialMedia {
            name
            url
          }
          footerNav {
            ... on SanityBlogOverviewPage {
              id: _id
              title
              type: _type
              slug {
                current
              }
            }
            ... on SanityPage {
              id: _id
              title
              type: _type
              slug {
                current
              }
            }
            ... on SanityPost {
              id: _id
              title
              type: _type
              slug {
                current
              }
              dateSegment: publishedAt(formatString: "YYYY/MM")
            }
          }
          mainNav {
            ... on SanityBlogOverviewPage {
              id: _id
              title
              type: _type
              slug {
                current
              }
            }
            ... on SanityPage {
              id: _id
              title
              type: _type
              slug {
                current
              }
            }
            ... on SanityPost {
              id: _id
              title
              type: _type
              slug {
                current
              }
              dateSegment: publishedAt(formatString: "YYYY/MM")
            }
          }
          startPage {
            id: _id
            slug {
              current
            }
          }
        }
      }
    `
  );

  const menuItems = mainNav
    ? mainNav.map(({ id, title, type, slug: { current: slug }, dateSegment }) => ({
        link: getUrlFromNavItem({ id, type, slug, dateSegment }, startPageId),
        name: title,
      }))
    : [];

  const footerLinks = footerNav
    ? footerNav.map(({ id, title, type, slug: { current: slug }, dateSegment }) => ({
        link: getUrlFromNavItem({ id, type, slug, dateSegment }, startPageId),
        name: title,
      }))
    : [];

  return (
    <>
      <Header key="main-header-key" menuItems={menuItems} companyInfo={companyInfo} />
      <main>{children}</main>
      <Footer footerLinks={footerLinks} socialMedia={socialMedia} slogan={slogan} companyInfo={companyInfo} />
    </>
  );
}
