export function filterPostsByCategoryId(postEdges, categoryId) {
  if (!postEdges) return [];
  return postEdges
    .map((edge) => edge.node)
    .filter((post) => post.categories && post.categories.find((category) => category.id === categoryId));
}

export function findImageAssetByRef(images, id) {
  if (!id || !images || !images.nodes) {
    return undefined;
  }

  const imageAsset = images.nodes.find((imgNode) => imgNode.id === id);
  if (!imageAsset) {
    return undefined;
  }

  return imageAsset.localFile && imageAsset.localFile;
}

export function getUrlFromNavItem({ id, type, slug, dateSegment }, startPageId) {
  switch (type) {
    case 'blogOverviewPage':
    case 'page':
      return id === startPageId ? '/' : `/${slug}/`;

    case 'post':
      return getBlogUrl(dateSegment, `/${slug}/`);

    default:
      return '/';
  }
}

export function getBlogUrl(dateSegment, slug) {
  return `/blog/${dateSegment}/${slug.current || slug}/`;
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function isFluidImage(mainImage) {
  return (
    mainImage &&
    mainImage.asset &&
    mainImage.asset.localFile &&
    mainImage.asset.localFile.childImageSharp &&
    mainImage.asset.localFile.childImageSharp.fluid
  );
}

export function isFluidAsset(asset) {
  return asset && asset.localFile && asset.localFile.childImageSharp && asset.localFile.childImageSharp.fluid;
}

export function isFixedImage(mainImage) {
  return (
    mainImage &&
    mainImage.asset &&
    mainImage.asset.localFile &&
    mainImage.asset.localFile.childImageSharp &&
    mainImage.asset.localFile.childImageSharp.fixed
  );
}

export function isFixedAsset(asset) {
  return asset && asset.localFile && asset.localFile.childImageSharp && asset.localFile.childImageSharp.fixed;
}

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export function getExcerpt(str = '', limit = 100) {
  const fullText = str || '';
  let shortText = str || '';
  if (shortText.length > limit) {
    shortText = shortText.substr(0, shortText.lastIndexOf(' ', limit)) + '...';
  }
  return {
    fullText,
    shortText,
  };
}
