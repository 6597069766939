import React, { useEffect, useState } from 'react';
import { animated as a, useSpring } from 'react-spring';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import ImgLogo from '../../images/logo-white-colored-red-subline.svg';
import { Container } from '../container/container';
import BurgerMenu from './mobile-menu/burger-menu';
import { MobileLink } from './mobile-menu/mobile-link';
import { ExternalLinkButton } from '../button/external-link-button';
import { InternalLink } from '../link/internal-link';
import { Link } from 'gatsby';
import { useMedia } from '../../hooks/useMedia';
import styles from './header.module.scss';

export function Header(props) {
  const {
    menuItems = [],
    companyInfo: { email, name: companyName },
  } = props;
  const [open, setOpen] = useState();

  const shouldCloseMenu = useMedia(['(min-width: 768px)'], [true], false);

  useEffect(() => {
    if (open) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      disableBodyScroll(document.getElementsByTagName('body')[0]);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [open]);

  useEffect(() => {
    if (shouldCloseMenu) {
      setOpen(false);
    }
  }, [shouldCloseMenu]);

  const menuContainerProps = useSpring({
    pointerEvents: open ? 'all' : 'none',
    opacity: open ? 1 : 0,
    config: { mass: 1, tension: 560, friction: 35, clamp: true },
  });

  const ctaProps = useSpring({
    width: open ? '100%' : '0%',
    config: { mass: 1, tension: 360, friction: 45, clamp: true },
    delay: open ? 600 : 100, // Normally we would use chaining but the use case is too simple
  });

  return (
    <Container component="header" className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={ImgLogo} alt={companyName} />
        </Link>
      </div>
      <div className={styles.nav}>
        <div className={styles.linksContainer}>
          {menuItems.map(({ link, name }, index) => {
            return (
              <InternalLink key={`${link}-${index}-key`} to={link}>
                {name}
              </InternalLink>
            );
          })}
        </div>
        {email && (
          <ExternalLinkButton className={styles.button} href={`mailto:${email}`} targetBlank={false}>
            Email Me Now
          </ExternalLinkButton>
        )}
      </div>

      <BurgerMenu isOpen={open} onClick={() => setOpen(!open)} />

      <a.div style={menuContainerProps} className={styles.mobileMenuContainer}>
        <Container className={styles.container} variant="normal">
          <div className={styles.linksContainer}>
            {menuItems.map((link, idx) => {
              return (
                <MobileLink key={`link-${idx}-${link}`} isOpen={open} link={link} onClick={() => setOpen(false)} />
              );
            })}
          </div>
          {email && (
            <a.div style={ctaProps} className={styles.buttonHider}>
              <ExternalLinkButton className={styles.button} href={`mailto:${email}`} targetBlank={false}>
                Email Me Now
              </ExternalLinkButton>
            </a.div>
          )}
        </Container>
      </a.div>
    </Container>
  );
}
