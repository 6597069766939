import React from 'react';
import clsx from 'clsx';
import styles from './container.module.scss';

export function Container({ className, children, tag: component = 'section', variant = 'normal', ...other }) {
  const Component = component;
  return (
    <Component
      className={clsx(
        variant === 'normal' && styles.normal,
        variant === 'thin' && styles.thin,
        variant === 'wide' && styles.wide,
        variant === 'extra-thin' && styles.extraThin,
        className
      )}
      {...other}
    >
      {children}
    </Component>
  );
}
