/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export function SEO({ description, title, keywords, lang = 'de' }) {
  const { sanitySiteSettings: site, sanityCompanyInfo: companyInfo } = useStaticQuery(
    graphql`
      query {
        sanitySiteSettings(_id: { eq: "siteSettings" }) {
          title
          description
          keywords
        }
        sanityCompanyInfo(_id: { eq: "companyInfo" }) {
          name
        }
      }
    `
  );

  const metaDescription = description || site.description;
  const metaKeywords = keywords || site.keywords;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
    >
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content="index, follow" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={companyInfo.name} />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:creator" content={site.author} />
      <meta property="twitter:title" content={title} />
      <meta name="twitter:site" content={companyInfo.name} />
      <meta property="twitter:description" content={metaDescription} />

      <meta property="msapplication-TileColor" content="#08080D" />
      <meta property="theme-color" content="#C62D34" />
    </Helmet>
  );
}
