import React from 'react';
import alexLogo from '../../images/logo-white-on-black-alex-hauk.svg';
import { Container } from '../container/container';
import { InternalLink } from '../link/internal-link';
import linkStyles from '../link/internal-link.module.scss';
import clsx from 'clsx';
import styles from './footer.module.scss';

export function Footer({
  footerLinks = [],
  companyInfo: { name, email, street, zip, city, phone },
  slogan,
  socialMedia = [],
}) {
  return (
    <Container className={styles.wrapper} tag="footer">
      <div className={styles.footerTitle}>
        <p> {slogan}</p>
      </div>
      <div className={styles.footerDetails}>
        <div className={styles.footerContactDetails}>
          <div className={styles.headTitle}>Contact Details</div>
          <ul>
            <li>
              <p>
                {street}
                <br />
                {zip} {city}
              </p>
            </li>
            <li className={styles.emailRed}> {email} </li>
            <li>{phone}</li>
          </ul>
        </div>
        <div className={styles.footerLinks}>
          <div>
            <div className={styles.headTitle}>Links</div>
            <ul>
              {footerLinks.map(({ link, name }, index) => (
                <li key={name}>
                  <InternalLink key={`${link}-${index}-key`} to={link}>
                    {name}
                  </InternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.footerFollow}>
          <div className={styles.headTitle}>Follow Me</div>
          <ul>
            {socialMedia.map(({ url, name }) => (
              <li key={`social-media-${name}`}>
                <a
                  className={clsx(linkStyles.link, linkStyles.hoverAnimation)}
                  href={url}
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.footerEnding}>
        <div>
          <img src={alexLogo} className={styles.alexLogo} alt={name} />
        </div>
        <div>© {new Date().getFullYear()} by Alex Hauk - All rights reserved</div>
      </div>
    </Container>
  );
}
