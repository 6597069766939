import React from 'react';
import clsx from 'clsx';
import styles from './button.module.scss';

const targetBlankDefaultProps = {
  target: '_blank',
  rel: 'noreferrer noopener',
};

export function ExternalLinkButton({ href, className, targetBlank = true, children, ...other }) {
  const targetBlankProps = targetBlank ? targetBlankDefaultProps : {};
  return (
    <a href={href} className={clsx(styles.button, className)} {...other} {...targetBlankProps}>
      {children}
    </a>
  );
}
