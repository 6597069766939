import React, { useState } from 'react';
import { useTrail, animated as a, useSpring } from 'react-spring';
import { Link } from 'gatsby';
import styles from './mobile-link.module.scss';

export function MobileLink({ isOpen, link: { link, name }, onClick }) {
  const [isCurrent, setIsCurrent] = useState(false);
  const letters = name.split('');
  const [trail, setTrail] = useTrail(letters.length, () => ({
    opacity: 0,
    transform: `translateX(1em)`,
  }));

  setTrail({
    opacity: isOpen ? 1 : 0,
    transform: `translateX(-${isOpen ? 0 : 1} em)`,
    delay: 200,
    config: { mass: 1, tension: 560, friction: 35, clamp: true },
  });

  const underlineSpring = useSpring({
    transform: `scaleX(${isCurrent && isOpen ? 1 : 0})`,
    delay: isOpen ? 600 : 100,
  });

  return (
    <Link
      to={link}
      className={styles.mobileLink}
      onClick={() => onClick()}
      getProps={(props) => setIsCurrent(props.isCurrent)}
    >
      {letters.map((l, idx) => {
        const i = letters.length - 1 - idx;
        const letterProps = {
          key: `${l}-${idx}`,
          style: trail[i],
        };
        if (l === ' ') return <a.span {...letterProps}>&nbsp;</a.span>;
        return <a.span {...letterProps}>{l}</a.span>;
      })}
      <a.span className={styles.underline} style={underlineSpring}></a.span>
    </Link>
  );
}
