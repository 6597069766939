import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import styles from './internal-link.module.scss';

export function InternalLink({ className, children, to, ...other }) {
  return (
    <Link
      className={clsx(styles.link, styles.hoverAnimation, className)}
      activeClassName={styles.active}
      to={to}
      {...other}
    >
      {children}
    </Link>
  );
}
