import React from 'react';
import styles from './burger.module.scss';

export function BurgerMenu(props) {
  const { isOpen, onClick } = props;
  return (
    <button
      name="menu-button"
      aria-label="Toggle menu"
      className={`${styles.burger} ${isOpen && styles.active}`}
      onClick={onClick}
      type="button"
    >
      <span className={styles.box}>
        <span className={styles.inner} />
      </span>
    </button>
  );
}

export default BurgerMenu;
